

























































































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "News",
  components: {},
  methods: {},
  data: () => ({}),
  computed: {
    ...mapState(["news"])
  },
  mounted() {
    this.$store.dispatch("loadNews");
  }
});
